import React, { useState, useEffect }        from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';

// import styles
import { ResetPasswordElement } from '../components/elements/ResetPassword/styles/ResetPasswordForm';

// import components
import UserIconCircled from '../components/elements/icons/UserIconCircled';
import CloseBtn        from '../components/elements/CloseBtn';
import Seo             from '../components/common/Seo';

const NewsletterSubscriptionPage = () => {
  const data = useStaticQuery( graphql`
    {
      allApiPoints {
        nodes {
          newsletter_subscription {
            text
            title
          }
        }
      }
    }
  `);

  // define the default component state
  const [texts, setTexts] = useState({ title: '', text: '' });
  const { title, text } = texts;

  /**
   * Get texts from the backend and processing changing language
   */
  useEffect( () => {
    // get loaded data from graphql
    data.allApiPoints.nodes.forEach( node => {
      const { newsletter_subscription } = node;
      const current = newsletter_subscription[1];
      const { title, text } = current;

      setTexts( { title, text} );
    });
  }, [data] );

  return (
    <>
      <Seo title={ title } />
      <ResetPasswordElement>
        <UserIconCircled className="icon" />
        <h4 className="title">{ title }</h4>
        <p className="description" dangerouslySetInnerHTML={ { __html: text } } />
        <CloseBtn className="close" onClick={ () => navigate( '/en/' ) } />
      </ResetPasswordElement>
    </>
  )
};

export default NewsletterSubscriptionPage;